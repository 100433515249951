import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import http from "./axios"

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'base-name',
            redirect: (to) => {
                if (to && to.path && to.path === "/") {
                    return "/dashboard"
                }
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('./views/dashboard/index')
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: () => import('./views/contacts/index')
        },
        {
            path: '/pledge-monitoring',
            name: 'pledge-monitoring',
            component: () => import('@/views/pledge-monitoring/PledgeMonitoringList'),
            meta: {
                permission: 'PLEDGE_MONITORING'
            }
        },
        {
            path: '/pledge-monitoring/:id/credit',
            name: 'pledge-monitoring-credit-list',
            component: () => import('@/views/pledge-monitoring/credit/CreditList.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_CREDIT'
            }
        },
        {
            path: '/pledge-monitoring/:id/credit/:creditId/pledge',
            name: 'pledge-monitoring-credit-pledge-list',
            component: () => import('@/views/pledge-monitoring/pledge/PledgeList.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_CREDIT_PLEDGE'
            }
        },
        {
            path: '/pledge-monitoring/application',
            name: 'pledge-monitoring-application',
            component: () => import('@/views/pledge-monitoring/application/Application.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_APPLICATION'
            }
        },
        {
            path: '/pledge-monitoring/application/:applicationId',
            name: 'pledge-monitoring-application-edit',
            component: () => import('@/views/pledge-monitoring/application/Application.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_APPLICATION_EDIT'
            }
        },
        {
            path: '/pledge-monitoring/application/view/:applicationId',
            name: 'pledge-monitoring-application-edit',
            component: () => import('@/views/pledge-monitoring/application/ApplicationView.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_APPLICATION_VIEW'
            }
        }
        /*{
            path: '/pledge-monitoring/:id/item/list',
            name: 'pledge-monitoring-edit-items-list',
            component: () => import('@/views/pledge-monitoring/PledgeMonitoringItemList'),
            meta: {
                permission: 'PLEDGE_MONITORING',
                actionPermission: 'PLEDGE_MONITORING_EDIT'
            }
        },
        {
            path: '/pledge-monitoring/:id/item/new',
            name: 'pledge-monitoring-item-new',
            component: () => import('@/views/pledge-monitoring/item/PledgeMonitoringItemEdit'),
            meta: {
                permission: 'PLEDGE_MONITORING',
                actionPermission: 'PLEDGE_MONITORING_NEW_ITEM'
            }
        },
        {
            path: '/pledge-monitoring/:id/item/edit/:itemId',
            name: 'pledge-monitoring-item-edit',
            component: () => import('@/views/pledge-monitoring/item/PledgeMonitoringItemEdit'),
            meta: {
                permission: 'PLEDGE_MONITORING',
                actionPermission: 'PLEDGE_MONITORING_EDIT_ITEM'
            }
        }*/
    ]
})

router.beforeEach((to, from, next) => {

    if (to.path.split("/")[1] !== from.path.split("/")[1]) {
        store.dispatch("deleteFiltersData")
    }

    next()

    return next;
});

export default router;