import { render, staticRenderFns } from "./HeaderPanel.vue?vue&type=template&id=56a0a9aa&scoped=true&"
import script from "./HeaderPanel.vue?vue&type=script&lang=js&"
export * from "./HeaderPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a0a9aa",
  null
  
)

export default component.exports