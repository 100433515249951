import Vue from 'vue';
import Vuex from 'vuex';
import Keycloak from "keycloak-js";
import router from "./router";
import i18n from "@/i18n"
import App from "./App.vue";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_RESET = "LOGIN_RESET";
export const PROFILE = "PROFILE";
export const LOGOUT = "LOGOUT";
export const MAIN_LOADING = "MAIN_LOADING";
export const GLOBAL_LOADING = "GLOBAL_LOADING";
export const KEYCLOAK = "KEYCLOAK";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_MENUS = "SET_MENUS";
export const SET_PROVINCES = "SET_PROVINCES";
export const SET_REGIONS = "SET_REGIONS";
export const SET_WITH_PROPERTY_OBJECT_BY_CADASTRE = "SET_WITH_PROPERTY_OBJECT_BY_CADASTRE"

export const ADD_FILTER_DATA = "ADD_FILTER_DATA";
export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA";
export const DELETE_FILTER_DATA = "DELETE_FILTER_DATA";
export const DELETE_FILTERS_DATA = "DELETE_FILTERS_DATA";

export const SET_FILTER_REGIONS = "SET_FILTER_REGIONS";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        profile: {},
        isAdmin: false,
        mainLoading: false,
        isGlobalLoading: false,
        isLoggedIn: false,
        loginProgress: false,
        loginError: '',
        permissions: [],
        menus: [],
        keycloak: new Keycloak({
            url: process.env.VUE_APP_KEYCLOAK_API_URL,
            realm: process.env.VUE_APP_KEYCLOAK_REALM,
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
            onLoad: 'login-required'
        }),
        cadastrePropertyObject: null,
        provinces: [],
        regions: [],
        filtersData: {},
        filterRegions: []
    },
    mutations: {
        [LOGIN](state) {
            state.loginProgress = true;
        },
        [LOGIN_SUCCESS](state) {
            state.loginProgress = false;
            state.isLoggedIn = true;
        },
        [LOGIN_FAILURE](state) {
            state.loginProgress = false;
            state.isLoggedIn = false;
            state.loginError = 'Имя пользователя и / или пароль недействительны';
        },
        [LOGIN_RESET](state) {
            state.loginError = '';
        },
        [LOGOUT](state) {
            state.isLoggedIn = false;
            state.profile = {};
            state.isAdmin = false;
        },
        [PROFILE](state, payload) {
            state.isLoggedIn = true;
            state.profile = payload;
            state.isAdmin = false;
            if (state.profile && state.profile.roles && state.profile.roles.length > 0) {
                for (const role of state.profile.roles) {
                    if (role === 'ADMIN') {
                        state.isAdmin = true;
                    }
                }
            }
        },
        [MAIN_LOADING](state, loading) {
            state.mainLoading = loading;
        },
        [GLOBAL_LOADING](state, loading) {
            state.isGlobalLoading = loading;
        },
        [SET_PERMISSIONS](state, data) {
            state.permissions = data
        },
        [SET_MENUS](state, data) {
            state.menus = data
        },
        [SET_PROVINCES](state, provinces) {
            state.provinces = provinces
        },
        [SET_REGIONS](state, regions) {
            state.regions = regions
        },
        [SET_WITH_PROPERTY_OBJECT_BY_CADASTRE](state, cadastrePropertyObject) {
            state.cadastrePropertyObject = cadastrePropertyObject
        },
        [KEYCLOAK](state) {
            state.keycloak.init({
                onLoad: 'login-required'
            }).then((auth) => {
                if (!auth){
                    window.location.reload()
                } else {
                    new Vue({
                        router,
                        store,
                        i18n,
                        render: h => h(App, {props: {keycloak: state.keycloak}}),
                    }).$mount('#app')
                    localStorage.setItem("x_token", state.keycloak.token)
                    localStorage.setItem("x_refresh_token", state.keycloak.refreshToken)
                }

                // Token Refresh
                setInterval(() => {
                    store.dispatch("logOut", state.keycloak)
                }, 12 * 3600 * 1000)

            }).catch(error => {
                console.error(error)
            });
        },
        [ADD_FILTER_DATA](state, {filterName, data}) {
            state.filtersData[filterName] = data
        },
        [UPDATE_FILTER_DATA](state, {filterName, newData}) {
            state.filtersData[filterName] = newData
        },
        [DELETE_FILTER_DATA](state, filterName) {
            if(state.filtersData.hasOwnProperty(filterName)) {
                delete state.filtersData[filterName]
            }
        },
        [DELETE_FILTERS_DATA](state) {
            state.filtersData = {}
        },
        [SET_FILTER_REGIONS](state, regions) {
            state.filterRegions = regions
        }
    },
    actions: {
        keycloak({commit}) {
            commit(KEYCLOAK);
        },
        logIn({commit, dispatch}, creds) {
        },
        logInReset({commit}) {
            commit(LOGIN_RESET);
        },
        logOut({commit}, keycloak) {
            localStorage.removeItem("x_token");
            keycloak.logout();
            commit(LOGOUT);
        },
        initProfile({commit}, keycloak) {
            keycloak.loadUserProfile().then(res => {
                Vue.prototype.$http.get('user/profile').then(profileRes => {
                    if(res) {
                        commit(PROFILE, {
                            firstName: res?.firstName,
                            lastName: res?.lastName,
                            username: res?.username,
                            roles: keycloak?.resourceAccess[process.env.VUE_APP_KEYCLOAK_CLIENT_ID]?.roles,
                            fullData: profileRes?.data?.data || {}
                        });
                    }
                }).catch(err => {
                    console.error(err)
                    commit(PROFILE, {});
                })
                commit(MAIN_LOADING, false);
            }).catch(err => {
                console.error(err)
                commit(PROFILE, {});
            })
        },
        initMenus({commit}) {
            Vue.prototype.$http.get('permission/user/menu')
                .then(res => {
                    if(res && res.data && res.data.menus) {
                        commit(SET_MENUS, res.data.menus)
                    }
                }).catch((err) => {
                console.error(err);
            });
        },
        setPermission({commit}, permissions) {
            commit(SET_PERMISSIONS, permissions);
        },
        setMenus({commit}, menus) {
            commit(SET_MENUS, menus);
        },
        setProvinces({commit}, provinces) {
            commit(SET_PROVINCES, provinces);
        },
        setRegions({commit}, regions) {
            commit(SET_REGIONS, regions);
        },
        setGlobalLoading({commit}, loading) {
            commit(GLOBAL_LOADING, !!loading)
        },
        setCadastrePropertyObject({commit}, cadastrePropertyObject) {
            commit(SET_WITH_PROPERTY_OBJECT_BY_CADASTRE, cadastrePropertyObject)
        },
        addFilterData({commit, state}, {filterName, data}) {
            commit(ADD_FILTER_DATA, {filterName, data})
        },
        updateFilterData({commit}, {filterName, newData}) {
            commit(UPDATE_FILTER_DATA, {filterName, newData})
        },
        deleteFilterData({commit}, filterName) {
            commit(DELETE_FILTER_DATA, filterName)
        },
        deleteFiltersData({commit}) {
            commit(DELETE_FILTERS_DATA)
        },
        setFilterRegions({commit}, regions) {
            commit(SET_FILTER_REGIONS, regions)
        }
    },
    getters: {
        getFilterData: state => filterName => {
            if(state.filtersData.hasOwnProperty(filterName) && state.filtersData[filterName]) {
                return state.filtersData[filterName]
            }
            return false
        },
        getProfileCompanies: state => {
            if(state.profile.fullData && state.profile.fullData.hasOwnProperty("companies") && state.profile.fullData.companies.length > 0) {
                return state.profile.fullData.companies
            }
            return []
        }
    }

});

export default store;